export const userRoleTableData = {
  columns: [
    "Feature",
    "Read",
    "Lists View",
    "Details View",
    "Search",
    "Create",
    "Update",
    "Upload",
    "Delete",
    "Download",
  ],
  rows: [
    {
      feature: "Platform Users",
      featureId: "F001",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Platform Users - Providing Access",
      featureId: "F002",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Standard Dashboard",
      featureId: "F003",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Platform Company Accounts Info",
      featureId: "F004",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account",
      featureId: "F005",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Highlights",
      featureId: "F006",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Details",
      featureId: "F007",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Projects",
      featureId: "F008",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: true,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Workflows",
      featureId: "F009",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Documents",
      featureId: "F010",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Contacts",
      featureId: "F011",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Account Contact Finance",
      featureId: "F012",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project",
      featureId: "F013",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project Financials",
      featureId: "F014",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project Details",
      featureId: "F015",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project History",
      featureId: "F016",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project Team Members",
      featureId: "F017",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project Timesheet",
      featureId: "F018",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project R&D Override",
      featureId: "F019",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project Journal",
      featureId: "F020",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project Documents",
      featureId: "F021",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Project R&D History",
      featureId: "F022",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Interactions",
      featureId: "F023",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Tasks",
      featureId: "F024",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Claims",
      featureId: "F025",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Claims Related Projects",
      featureId: "F026",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Claims Summary",
      featureId: "F027",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Standard Reports",
      featureId: "F028",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Documents",
      featureId: "F029",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Workbench",
      featureId: "F030",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Standard Workflows",
      featureId: "F031",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Portfolio",
      featureId: "F032",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Contacts",
      featureId: "F033",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Contacts Details",
      featureId: "F034",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Contacts Projects",
      featureId: "F035",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Contacts R&D Expense",
      featureId: "F036",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Contact Salary",
      featureId: "F037",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Training & Documentation",
      featureId: "F038",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Home Page - KPIs",
      featureId: "F039",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Home Page - Activity section",
      featureId: "F040",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
    {
      feature: "Alerts",
      featureId: "F041",
      permissions: [
        {
          permissionName: "Read",
          permissionId: "P000000003",
          status: false,
        },
        {
          permissionName: "List view",
          permissionId: "P000000008",
          status: false,
        },
        {
          permissionName: "Details View",
          permissionId: "P000000004",
          status: false,
        },
        {
          permissionName: "Search",
          permissionId: "P000000009",
          status: false,
        },
        {
          permissionName: "Create",
          permissionId: "P000000007",
          status: false,
        },
        {
          permissionName: "Update",
          permissionId: "P000000001",
          status: false,
        },
        {
          permissionName: "Upload",
          permissionId: "P000000002",
          status: false,
        },
        {
          permissionName: "Delete",
          permissionId: "P000000005",
          status: false,
        },
        {
          permissionName: "Download",
          permissionId: "P000000006",
          status: false,
        },
      ],
    },
  ],
};
