import { Box, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BarChart from "../../components/Common/BarChart";
import ActivitySection from "../../components/Homepage/ActivitySection";
import Alerts from "../../components/Homepage/AlertsSection";
// import News from "../../components/Homepage/News";
import axios from "axios";
import Notification from "../../components/Homepage/Notification";
import { BaseURL } from "../../constants/Baseurl";
import { useAuthContext } from "../../context/AuthProvider";
import { NotificationContext } from "../../context/NotificationContext";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { Authorization_header } from "../../utils/helper/Constant";
import toast from "react-hot-toast";

const paperContainerStyle = {
  width: "98%",
  mx: "auto",
  mt: 2,
  p: 2,
  borderRadius: "20px",
  boxShadow: "0px 3px 6px #0000001F",
};

const flexBoxStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline",
};

const greetingTextStyle = {
  color: "#FD5707",
  display: "inline-flex",
  fontSize: "22px",
  fontWeight: "500",
};

const chartPaperStyle = {
  p: 1,
  flex: 1,
  borderRadius: "20px",
  height: "500px",
  boxShadow: "0px 3px 6px #0000001F",
};

const layoutBoxStyle = {
  width: "98%",
  mx: "auto",
  display: "flex",
  mt: 1,
  gap: "20px",
};

const newsPaperStyle = {
  height: "300px",
  overflow: "hidden",
  borderRadius: "20px",
  flex: 1,
  boxShadow: "0px 3px 6px #0000001F",
};

const subTextStyle = {
  color: "#404040",
  display: "inline-flex",
  fontSize: "18px",
  ml: 1,
};

function Home() {
  const { notifData, alerts } = useContext(NotificationContext);
  const { authState } = useAuthContext();
  const [data, setData] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [rndExpenses, setRndExpenses] = useState([]);
  const [projects, setProjects] = useState(null);
  const [uncertainHrs, setUncertainHrs] = useState(null);
  const [timesheets, setTimesheets] = useState(null);
  const [totalUncertainHrs, setTotalUncertainHrs] = useState(null);
  const [companiWiseMatrix, setCompaniwiseMatrix] = useState([]);
  const [totalExpense, setTotalExpense] = useState([]);
  const { logout } = useAuthContext();
  // const [noteModal, setNoteModal] = useState(
  //   // authState?.userInfo?.isPassResetRequired
  //   // false
  //   null
  // );
  // useEffect(() => {
  //   setNoteModal(authState?.userInfo?.isPassResetRequired);
  // }, []);

  // const handleNoteModalClose = () => {
  //   setNoteModal(false);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get(
          `${BaseURL}/api/v1/home/${localStorage.getItem("userid")}/get-kpis`, Authorization_header()
        );

        setCompaniwiseMatrix(response1.data.data.companyWiseMetrics);
        // const newCompanies = companiWiseMatrix.map(c => c.companyName);
        // setCompanies(newCompanies);
        // setCompanies(response1.data.data?.rndPercent?.companyName);
        // setRndExpenses(response1.data.data?.rndPercent?.percentageRnD);
        // setProjects(response1.data.data?.hoursPercent?.projectName);
        // setUncertainHrs(response1.data.data?.hoursPercent?.percentageRnDHour);
        // setTimesheets(response1.data.data?.projectsClaim?.timesheets);
        // setTotalUncertainHrs(
        //   response1.data.data?.projectsClaim?.totalUncertainHours
        // );
      } catch (error) {
        console.error("error message :", error?.response?.data?.message);
        if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
          toast.error("Session expired, you need to login again");
          logout();
        }
        if (error) {

        }
        console.error(error);
      }
    };

    fetchData();
  }, [localStorage?.getItem("keys")])

  useEffect(() => {
    const newCompanies = companiWiseMatrix.map(c => c.companyName);
    setCompanies(newCompanies);
    const rndExpenseCumulativeSum = companiWiseMatrix.map(c => c.rndExpenseCumulativeSum);
    setRndExpenses(rndExpenseCumulativeSum);
    const totalExpenseSum = companiWiseMatrix.map(c => c.totalExpenseSum);
    setTotalExpense(totalExpenseSum);

  }, [localStorage?.getItem("keys"), companiWiseMatrix]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseURL}/api/v1/interactions/${localStorage.getItem("userid")}/${authState?.userInfo?.companyId
          }/get-interactions`, Authorization_header()
        );

        setData(response?.data?.data);
      } catch (error) {
        if (error?.response?.data?.logout === true) {
          toast.error("Session expired, you need to login again");
          logout();
        }
        console.error(error);
      }
    };

    fetchData();

  }, [localStorage?.getItem("keys")]);


  const customLabel = {
    rotation: 0,
    format: "{text}",
    overflow: "justify",
  };
  const customLabel1 = {
    rotation: 0,
    format: "{text}%",
    overflow: "justify",
  };
  const customLabel3 = {
    rotation: 0,
    format: "{text}",
    overflow: "justify",
  };
  return (
    <div>
      {/* <Paper sx={paperContainerStyle}> */}
      {/* <Box sx={flexBoxStyle}>
          <Typography sx={greetingTextStyle}>{notifData?.message}</Typography>
          <Typography sx={subTextStyle}>
            Hope you are having a good day!
          </Typography>
        </Box> */}
      {/* <Notification tasks={alerts} /> */}
      {/* </Paper> */}
      {useHasAccessToFeature("F039", "P000000008") && (
        <Box sx={layoutBoxStyle}>
          <Paper sx={chartPaperStyle}>
            <BarChart
              title={"R&D Expense By Account (% of Total Expense)"}
              data={rndExpenses}
              categories={companies}
              redirect={false}
              customLabel={customLabel1}
              dataType={"percentage"}
              labels={companies}
              xaxis={"Accounts"}
              yaxis={"Total R&D Expenses"}
            />
          </Paper>
          <Paper sx={chartPaperStyle}>
            <BarChart
              title={"Total Expenses By Account"}
              data={totalExpense}
              categories={companies}
              redirect={false}
              customLabel={customLabel}
              xaxis={"Accounts"}
              yaxis={"Total Expenses"}
            />
          </Paper>
          {/* <Paper sx={chartPaperStyle}>
            <BarChart
              title={"Total Uncertain Hours By Timesheet (in hrs)"}
              data={totalUncertainHrs}
              categories={timesheets}
              redirect={false}
              customLabel={customLabel3}
            />
          </Paper> */}
        </Box>
      )}
      {/* <Box sx={{ ...layoutBoxStyle, mb: 2 }}>
        {useHasAccessToFeature("F023", "P000000008") && (
          <Paper sx={newsPaperStyle}>
            <ActivitySection data={data} />
          </Paper>
        )}
        {useHasAccessToFeature("F041", "P000000008") && (
          <Paper sx={newsPaperStyle}>
            <Alerts data={alerts} />
          </Paper>
        )}
      </Box> */}
      {/* <Box sx={{ ...layoutBoxStyle, mb: 5 }}>
        <Paper sx={newsPaperStyle}>
          <News />
        </Paper>
      </Box> */}
      {/* <ResetPasswordModal open={noteModal} handleClose={handleNoteModalClose} /> */}
    </div>
  );
}

export default Home;
