import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import MultiLineTextField from '../MultiLineTextField';
import axios from 'axios';
import { BaseURL } from '../../../constants/Baseurl';
import TypographyDemo from '../../Common/TypographyDemo';
import { Authorization_header } from '../../../utils/helper/Constant';

const styles = {
    buttonStyle: {
        mr: 1,
        ml: 14,
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#9F9F9F",
        color: "white",
        "&:hover": { backgroundColor: "#9F9F9F" }
    }
}




const CaseInteractionDetails = ({ handleShowInteractionListing, interactionId, intrIndentifier }) => {
    const [message, setMessage] = useState("");
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const [loading, setLoading] = useState(false);

    const getInteractionDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BaseURL}/api/v1/projects/${localStorage?.getItem("userid")}/${interactionId}/interaction`,Authorization_header())
            setMessage(response?.data?.data?.message);
            setQuestionAnswers(response?.data?.data?.interactionDetails)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }


    };

    useEffect(() => {
        getInteractionDetails();
    }, [interactionId]);

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "row", }}>
                <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontSize: "1rem", lineHeight: "1.5px", marginLeft: "1.5rem", marginTop: "0.5rem", cursor: "pointer" }} onClick={handleShowInteractionListing}>
                        <span
                            style={{ color: "#29B1A8", cursor: "pointer", }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                        >Interactions{">"}
                        </span>
                        {intrIndentifier}
                    </Typography>
                    {loading ? <TypographyDemo /> :
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", overflowY: "auto" }}>
                            {questionAnswers?.map((qn, index) => (
                                <Box sx={{ display: "flex", flexDirection: "column", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", marginBottom: "10px", padding: "10px", borderRadius: "5px" }} key={qn?.id}>
                                    <Box width={"97%"}>{index + 1}. {qn?.question}</Box>
                                    <Box width={"97%"}>
                                        <MultiLineTextField value={qn?.answer} />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

export default CaseInteractionDetails;