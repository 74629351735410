import { CircularProgress, Paper, Table, TableContainer, Box, Drawer, Badge } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomPagination from "../../components/Common/CustomPagination";
import TableHeader from "../../components/Common/TableHeader";
import TableIntro from "../../components/Common/TableIntro";
import CasesTableBody from "../../components/Cases/CasesTableBody";
import toast, { Toaster } from "react-hot-toast";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import { FilterListContext } from "../../context/FiltersListContext";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import CaseModalForm from "../../components/Cases/CaseModalForm";
import { CaseContext } from "../../context/CaseContext";
import { Authorization_header } from "../../utils/helper/Constant";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { HiFilter } from "react-icons/hi";
import TimesheetFilters from "../../components/Timesheets/TimesheetFilter";
import ContactFilters from "../../components/Contacts/ContactFilters";
import CaseFilter from "../../components/Cases/CaseFilter";

const tableData = {
  columns: [
    "Case Code",
    "Case type",
    "Account",
    "Location",
    "Case Owner",
    "Created On",
  ],
};

const opacityValue = -1;
const styleConstants = {
  filterDownloadStyle: {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "28px",
    padding: "5px",
    marginRight: "16px",
    cursor: "pointer",
    // opacity: opacityValue,
  },
  tableContainerStyle: {
    borderLeft: "1px solid #E4E4E4",
    // backgroundColor: `rgba(255, 255, 255, ${opacityValue})`,  // Apply the same opacity to TableContainer
  },
};

function Cases(page, documentType = "", onApplyFilters) {
  const { pinnedObject } = usePinnedData();
  const {
    getAllCases,
    caseData,
    caseFilterState,
    currentState,
    loading,
    setCaseFilterState,
    setIsCaseFilterApplied,
    triggerCaseClearFilters,
    setCurrentState,
  } = useContext(CaseContext);
  const { clientList, fetchClientList } = useContext(FilterListContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [pinStates, setPinStates] = useState({
    "All Cases": false,
    "Recently Viewed": false,
  });

  const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);

  const handleFilterClick = () => {
    setDrawerOpen(!drawerOpen);
    if (!drawerOpen) {
      setFilterPanelOpen(true);
    }
  };
  const handleFilterPanelClose = () => {
    setFilterPanelOpen(false);
    setDrawerOpen(false);
  };
  const handleFilterClose = () => {
    setFilterPanelOpen(false);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  const currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const placeholderRow = {};
  while (currentData?.length < itemsPerPage) {
    currentData.push(placeholderRow);
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const appliedFilters = {
    Clients: caseFilterState?.company,
    Countryname: caseFilterState?.countryName,
    caseOwners: caseFilterState?.caseOwners,
  };
  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      getAllCases(filters);
    }
    else {
      getAllCases(filters);
      // toast.error("Please select at least one filter.");
    }
  };


  useEffect(() => {
    getAllCases();
  }, [Authorization_header]);

  useEffect(() => {
    fetchClientList();
    // setCurrentState(
    //   pinnedObject?.TIMESHEETS === "RV" ? "Recently Viewed" : "All Timesheets"
    // );
  }, [Authorization_header]);

  useEffect(() => {
    const updatedPinStates = {
      "All Cases": pinnedObject.CASES === "ALL",
      "Recently Viewed": pinnedObject.CASES === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.CASES]);


  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (caseData) {
      const filteredData = caseData?.filter(
        (task) => {
          return task?.caseCode?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.primaryContactName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.companyName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.primaryContactEmail?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.caseOwnerName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.caseId?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.caseType?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.countryName?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.createdOn?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
        }
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [caseData, search]);

  const isSearch = useHasAccessToFeature("F018", "P000000009");

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Cases"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Cases"] = true;
      }

      return newState;
    });
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Cases" ? "ALL" : "RV";
    }
  }, [pinStates]);
  useEffect(() => {
    const shouldFetchWithFiltersCase =
      caseFilterState?.company?.length > 0 ||
      caseFilterState?.companyId?.length > 0 ||
      caseFilterState?.countryName?.length > 0 ||
      caseFilterState?.caseOwnerName?.length > 0 ||
      caseFilterState?.sortField?.length > 0 ||
      caseFilterState?.sortOrder?.length > 0;
    if (shouldFetchWithFiltersCase) {
      let caseOptions = {
        ...(caseFilterState?.companyId?.length > 0 && {
          client: caseFilterState?.companyId,
        }),
        ...(caseFilterState.countryName?.length > 0 && {
          countryName: caseFilterState.countryName,
        }),
        ...(caseFilterState.caseOwners?.length > 0 && {
          caseOwners: caseFilterState.caseOwners,
        }),
      };
      getAllCases(caseOptions);
    } else {
      getAllCases();
    }
  }, [currentState, Authorization_header]);


  const countActiveFilters = () => {
    let count = 0;
    if (caseFilterState?.company?.length > 0) count += 1;
    if (caseFilterState?.countryName?.length > 0) count += 1;
    if (caseFilterState?.caseOwners?.length > 0) count += 1;
    return count;
  };


  return (
    <>
      <Box
        sx={{
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        {useHasAccessToFeature("F018", "P000000008") && (
          <Paper
            sx={{
              display: "flex",
              width: "98.5%",
              mx: "auto",
              mt: 1,
              flexDirection: "column",
              borderRadius: "20px",
              mb: 3,
              boxShadow: "0px 3px 6px #0000001F",
            }}>
            <TableIntro
              heading={
                pinnedObject?.CASES === "RV"
                  ? "Recently Viewed"
                  : "All Cases"
              }
              btnName={"Add Case"}
              page={"case"}
              totalItems={filteredRows?.length || 0}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onUploadClick={handleUploadClick}
              onSearch={handleSearch}
              latestUpdateTime={latestUpdateTime}
              items={["All Cases", "Recently Viewed"]}
              onApplyFilters={applyFiltersAndFetch}
              searchPermission={isSearch}
              appliedFilters={appliedFilters}
            />
            <CaseModalForm
              open={modalOpen}
              handleClose={handleModalClose}
              clients={clientList}
              handleFetchAllCases={getAllCases}
            />
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              changePage={handleChangePage}
              changeItemsPerPage={handleChangeItemsPerPage}
              minRows={20}
            />
            <Box sx={{ display: "flex", pt: 0, pb: page === "activity" ? -1 : 0 }}>
              <Box sx={{ marginLeft: "9px", marginTop: "-107px", display: "flex", alignItems: "center" }}>
                {!(page === "alerts") && (
                  <Badge
                    badgeContent={countActiveFilters()}
                    color="error"
                    overlap="circular"
                    sx={{
                      zIndex: 2,
                      marginRight: "0px",
                      '& .MuiBadge-badge': {
                        minWidth: '10px',
                        height: '16px',
                        fontSize: '10px',
                        paddingLeft: '5',
                        transform: 'translate(25%, -25%)',
                        backgroundColor: '#FD5707',
                      },
                    }}
                  >
                    <HiFilter
                      style={styleConstants.filterDownloadStyle}
                      onClick={handleFilterClick}
                    />
                  </Badge>
                )}
              </Box>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleFilterPanelClose}
                sx={{
                  width: '300px',
                  flexShrink: 0,
                }}
                variant="persistent"
              >
                {filterPanelOpen && (
                  <CaseFilter
                    handleClose={handleFilterPanelClose}
                    open={filterPanelOpen}
                    page={page}
                    documentType={documentType}
                    onApplyFilters={applyFiltersAndFetch}
                    style={{ position: 'absolute', left: 0 }}
                  />
                )}
              </Drawer>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: drawerOpen ? '300px' : '0',
              }}
            >
              <TableContainer sx={{
                maxHeight: "82vh",
                overflowY: "auto",
                borderTopLeftRadius: "20px",
              }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">

                  <TableHeader tableData={tableData} page={"cases"} />
                  <CasesTableBody
                    data={currentData}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                  />
                </Table>
                {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                      minHeight: "380px",
                    }}
                  >
                    <CircularProgress sx={{ color: "#00A398" }} />
                  </div>
                )}
              </TableContainer>
              {/* <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={handleChangePage}
                changeItemsPerPage={handleChangeItemsPerPage}
                minRows={20}
              /> */}
            </Box>
          </Paper>
        )}
        <Toaster />
      </Box >
    </>
  );
}

export default Cases;