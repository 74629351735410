import { createContext, useEffect, useState } from "react";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { useAuthContext } from "./AuthProvider";
import axios from "axios";
import { Authorization_header } from "../utils/helper/Constant";
import { BaseURL } from "../constants/Baseurl";

export const ProjectTeamContext = createContext();

export const ProjectTeamProvider = ({ children }) => {
    const { pinnedObject } = usePinnedData();
    const [teamMembers, setTeamMembers] = useState([]);
    const [projectTeamFilterState, setProjectTeamFilterState] = useState({
        contactId: [],
        companyId: [],
        contactName: [], // Only one instance of contactName
        company: [],
        projectCode: [],
        projectName: [],
        totalExpense: [0, null],
        rndExpense: [0, null],
        rndPotential: [0, null],
        totalHours: [0, null],
        employementType: [],
        hourlyRate: [0, null]
    });
    const [clearFilterTrigger, setClearFilterTrigger] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [sortParams, setSortParams] = useState({ sortField: null, sortOrder: null });
    const [currentState, setCurrentState] = useState(
        pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Project Team Members"
    );
    const [loading, setLoading] = useState(false);
    const [appliedSortParams, setAppliedSortParams] = useState("");
    const { logout } = useAuthContext();

    function getAccessToken() {
        const tokens = localStorage.getItem("tokens");
        const token_obj = JSON.parse(tokens);
        return token_obj?.accessToken || "";
    }

    function getProjectTeamSortParams({ sortField, sortOrder }) {
        console.log(sortField)
        switch (sortField) {
            case "Employee ID":
                sortField = "employeeId";
                break;
            case "Employee Name":
                sortField = "firstName";
                break;
            case "Employement Type":
                sortField = "employementType";
                break;
            case "Designation":
                sortField = "employeeTitle";
                break;
            case "Company Name":
                sortField = "companyName";
                break;
            case "Project Code":
                sortField = "projectCode";
                break;
            case "Project Name":
                sortField = "projectName"
                break;
            case "Total Hours":
                sortField = "totalHours";
                break;
            case "Hourly Rate":
                sortField = "hourlyRate";
                break;
            case "Total Cost":
                sortField = "totalCost";
                break;
            case "R&D Potential (%)":
                sortField = "rndPotential";
                break;
            case "R&D Credits":
                sortField = "rndCredits";
                break;
            case "QRE Cost":
                sortField = "qreCost";
                break;
            default:
                sortField = null;
                break;
        };
        setSortParams({ sortField: sortField, sortOrder: sortOrder });
    }

    useEffect(() => {
        console.log(sortParams);
        getProjectsTeamMembers();
    }, [sortParams])

    const getProjectsTeamMembers = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();
            if (sortParams?.sortField && sortParams?.sortOrder) {
                queryParams.append("sortField", sortParams.sortField);
                queryParams.append("sortOrder", sortParams.sortOrder);
            }
            const queryString = queryParams.toString();
            console.log(queryString);
            const url = `${BaseURL}/api/v1/contacts/get-team-members?${queryString && queryString}`;
            const response = await axios.get(url, Authorization_header());
            console.log(response?.data);
            setTeamMembers(response?.data);
        } catch (error) {
            console.error(error);
            if (error.response?.status === 401) {
                logout(); // Optionally handle unauthorized access by logging out
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProjectTeamContext.Provider
            value={{
                teamMembers,
                getProjectsTeamMembers,
                currentState,
                setCurrentState,
                loading,
                getProjectTeamSortParams
            }}
        >
            {children}
        </ProjectTeamContext.Provider>
    );
};
