import { useAuthContext } from "../../context/AuthProvider";

export const useHasAccessToFeature = (featureId, permissionId) => {
  const { authState } = useAuthContext();

  return authState?.rolesInfo?.some(
    (item) =>
      item.featureId === featureId &&
      item.permissionId === permissionId &&
      item.status === 1
  );
};

const permissionMapingData = {
  Update: "P000000001",
  Upload: "P000000002",
  "Read/PageView": "P000000003",
  DetailsView: "P000000004",
  Delate: "P000000005",
  Download: "P000000006",
  Create: "P000000007",
  ListView: "P000000008",
  Search: "P000000009",
};

const featureMappingData = [
  {
    featureId: "F001",
    featureIdentifier: "platformusers",
    featureTitle: "Platform Users",
  },
  {
    featureId: "F002",
    featureIdentifier: "platformusersprovidingaccess",
    featureTitle: "Platform Users - Providing Access",
  },
  {
    featureId: "F003",
    featureIdentifier: "standarddasboard",
    featureTitle: "Standard Dashboard",
  },
  {
    featureId: "F004",
    featureIdentifier: "platformcompanyaccountinfo",
    featureTitle: "Platform Company account Info",
  },
  {
    featureId: "F005",
    featureIdentifier: "account",
    featureTitle: "Account",
  },
  {
    featureId: "F006",
    featureIdentifier: "accounthighlights",
    featureTitle: "Account Highlights",
  },
  {
    featureId: "F007",
    featureIdentifier: "accountdetails",
    featureTitle: "Account Details",
  },
  {
    featureId: "F008",
    featureIdentifier: "accountprojects",
    featureTitle: "Account Projects",
  },
  {
    featureId: "F009",
    featureIdentifier: "accountworkflows",
    featureTitle: "Account Workflows",
  },
  {
    featureId: "F010",
    featureIdentifier: "accountdocuments",
    featureTitle: "Account Documents",
  },
  {
    featureId: "F011",
    featureIdentifier: "accountcontacts",
    featureTitle: "Account Contacts",
  },
  {
    featureId: "F012",
    featureIdentifier: "accountcontactfinance",
    featureTitle: "Account Contact Finance",
  },
  {
    featureId: "F013",
    featureIdentifier: "project",
    featureTitle: "Project",
  },
  {
    featureId: "F014",
    featureIdentifier: "projectfinancials",
    featureTitle: "Project Financials",
  },
  {
    featureId: "F015",
    featureIdentifier: "projectdetails",
    featureTitle: "Project Details",
  },
  {
    featureId: "F016",
    featureIdentifier: "projecthistory",
    featureTitle: "Project History",
  },
  {
    featureId: "F017",
    featureIdentifier: "projectteammembers",
    featureTitle: "Project Team Members",
  },
  {
    featureId: "F018",
    featureIdentifier: "projecttimesheet",
    featureTitle: "Project Timesheet",
  },
  {
    featureId: "F019",
    featureIdentifier: "projectrndoverride",
    featureTitle: "Project R&D Override",
  },
  {
    featureId: "F020",
    featureIdentifier: "projectjournal",
    featureTitle: "Project Journal",
  },
  {
    featureId: "F021",
    featureIdentifier: "projectdocuments",
    featureTitle: "Project Documents",
  },
  {
    featureId: "F022",
    featureIdentifier: "projectrndhistory",
    featureTitle: "Project R&D History",
  },
  {
    featureId: "F023",
    featureIdentifier: "interactions",
    featureTitle: "Interactions",
  },
  {
    featureId: "F024",
    featureIdentifier: "tasks",
    featureTitle: "Tasks",
  },
  {
    featureId: "F025",
    featureIdentifier: "claims",
    featureTitle: "Claims",
  },
  {
    featureId: "F026",
    featureIdentifier: "claimsrelatedprojects",
    featureTitle: "Claims Related Projects",
  },
  {
    featureId: "F027",
    featureIdentifier: "claimssummary",
    featureTitle: "Claims Summary",
  },
  {
    featureId: "F028",
    featureIdentifier: "standardreports",
    featureTitle: "Standard Reports",
  },
  {
    featureId: "F029",
    featureIdentifier: "documents",
    featureTitle: "Documents",
  },
  {
    featureId: "F030",
    featureIdentifier: "workbench",
    featureTitle: "Workbench",
  },
  {
    featureId: "F031",
    featureIdentifier: "standardworkflows",
    featureTitle: "Standard Workflows",
  },
  {
    featureId: "F032",
    featureIdentifier: "portfolio",
    featureTitle: "Portfolio",
  },
  {
    featureId: "F033",
    featureIdentifier: "contacts",
    featureTitle: "Contacts",
  },
  {
    featureId: "F034",
    featureIdentifier: "contactsdetails",
    featureTitle: "Contacts Details",
  },
  {
    featureId: "F035",
    featureIdentifier: "contactsprojects",
    featureTitle: "Contacts Projects",
  },
  {
    featureId: "F036",
    featureIdentifier: "contactsrdexpense",
    featureTitle: "Contacts R&D Expense",
  },
  {
    featureId: "F037",
    featureIdentifier: "contactsalary",
    featureTitle: "Contact Salary",
  },
  {
    featureId: "F038",
    featureIdentifier: "trainingdocumentation",
    featureTitle: "Training & Documentation",
  },
  {
    featureId: "F039",
    featureIdentifier: "homepagekpis",
    featureTitle: "Home Page - KPIs",
  },
  {
    featureId: "F040",
    featureIdentifier: "homepageactivitysection",
    featureTitle: "Home Page - Activity section",
  },
  {
    featureId: "F041",
    featureIdentifier: "homepagealertssection",
    featureTitle: "Home Page - Alerts section",
  },
];

// implement search permission on filter

const data = [
  {
    roleFeaturesId: "F001R00000002",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:19:43.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:19:43.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:19:43.000Z",
    sysModTime: "2024-03-06T12:19:43.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002CR",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:16:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:16:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:16:56.000Z",
    sysModTime: "2024-03-06T14:16:56.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002DL",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:30.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:30.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:30.000Z",
    sysModTime: "2024-03-06T15:09:30.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002DO",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002DV",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:02.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:02.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:02.000Z",
    sysModTime: "2024-03-06T13:02:02.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002LV",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:26.000Z",
    sysModTime: "2024-03-06T12:45:26.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002RD",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:32:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:32:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:32:50.000Z",
    sysModTime: "2024-03-06T14:32:50.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002UL",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:38.000Z",
    sysModTime: "2024-03-06T15:50:38.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F001R00000002UP",
    roleId: "R00000002",
    featureId: "F001",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:50:54.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:50:54.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:50:54.000Z",
    sysModTime: "2024-03-06T14:50:54.000Z",
    featureIdentifier: "platformusers",
    description: "some desc",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:19:46.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:19:46.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:19:46.000Z",
    sysModTime: "2024-03-06T12:19:46.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002CR",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:16:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:16:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:16:59.000Z",
    sysModTime: "2024-03-06T14:16:59.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002DL",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:33.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:33.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:33.000Z",
    sysModTime: "2024-03-06T15:09:33.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002DO",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002DV",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:05.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:05.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:05.000Z",
    sysModTime: "2024-03-06T13:02:05.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002LV",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:29.000Z",
    sysModTime: "2024-03-06T12:45:29.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002RD",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:32:53.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:32:53.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:32:53.000Z",
    sysModTime: "2024-03-06T14:32:53.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002UL",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:41.000Z",
    sysModTime: "2024-03-06T15:50:41.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F002R00000002UP",
    roleId: "R00000002",
    featureId: "F002",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:50:57.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:50:57.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:50:57.000Z",
    sysModTime: "2024-03-06T14:50:57.000Z",
    featureIdentifier: "platformusersprovidingaccess",
    description: "desc",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:05.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:05.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:05.000Z",
    sysModTime: "2024-03-06T12:21:05.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002CR",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:19.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:19.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:19.000Z",
    sysModTime: "2024-03-06T14:18:19.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002DL",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:58.000Z",
    sysModTime: "2024-03-06T15:10:58.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002DO",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002DV",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:26.000Z",
    sysModTime: "2024-03-06T13:03:26.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002LV",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:47.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:47.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:47.000Z",
    sysModTime: "2024-03-06T12:46:47.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002RD",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:14.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:14.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:14.000Z",
    sysModTime: "2024-03-06T14:34:14.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002UL",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:04.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:04.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:04.000Z",
    sysModTime: "2024-03-06T15:52:04.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F003R00000002UP",
    roleId: "R00000002",
    featureId: "F003",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:17.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:17.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:17.000Z",
    sysModTime: "2024-03-06T14:52:17.000Z",
    featureIdentifier: "standarddasboard",
    description: "homepage",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000009",
    status: 2,
    statusDate: "2024-03-06T12:19:49.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:19:49.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:19:49.000Z",
    sysModTime: "2024-03-06T12:19:49.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002CR",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:03.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:03.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:03.000Z",
    sysModTime: "2024-03-06T14:17:03.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002DL",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:36.000Z",
    sysModTime: "2024-03-06T15:09:36.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002DO",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002DV",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:08.000Z",
    sysModTime: "2024-03-06T13:02:08.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002LV",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000008",
    status: 2,
    statusDate: "2024-03-06T12:45:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:32.000Z",
    sysModTime: "2024-03-06T12:45:32.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002RD",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:32:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:32:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:32:56.000Z",
    sysModTime: "2024-03-06T14:32:56.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002UL",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:44.000Z",
    sysModTime: "2024-03-06T15:50:44.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F004R00000002UP",
    roleId: "R00000002",
    featureId: "F004",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:00.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:00.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:00.000Z",
    sysModTime: "2024-03-06T14:51:00.000Z",
    featureIdentifier: "platformcompanyaccountinfo",
    description: "Platform Company account Info",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:19:52.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:19:52.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:19:52.000Z",
    sysModTime: "2024-03-06T12:19:52.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002CR",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:06.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:06.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:06.000Z",
    sysModTime: "2024-03-06T14:17:06.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002DL",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:39.000Z",
    sysModTime: "2024-03-06T15:09:39.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002DO",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002DV",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:11.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:11.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:11.000Z",
    sysModTime: "2024-03-06T13:02:11.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002LV",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:35.000Z",
    sysModTime: "2024-03-06T12:45:35.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002RD",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:32:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:32:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:32:59.000Z",
    sysModTime: "2024-03-06T14:32:59.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002UL",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:47.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:47.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:47.000Z",
    sysModTime: "2024-03-06T15:50:47.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F005R00000002UP",
    roleId: "R00000002",
    featureId: "F005",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:03.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:03.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:03.000Z",
    sysModTime: "2024-03-06T14:51:03.000Z",
    featureIdentifier: "client",
    description: "Client",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000009",
    status: 2,
    statusDate: "2024-03-06T12:19:55.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:19:55.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:19:55.000Z",
    sysModTime: "2024-03-06T12:19:55.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002CR",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:09.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:09.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:09.000Z",
    sysModTime: "2024-03-06T14:17:09.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002DL",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:42.000Z",
    sysModTime: "2024-03-06T15:09:42.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002DO",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002DV",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:15.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:15.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:15.000Z",
    sysModTime: "2024-03-06T13:02:15.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002LV",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T16:26:10.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:26:10.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:26:10.000Z",
    sysModTime: "2024-03-06T16:26:10.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002RD",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:03.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:03.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:03.000Z",
    sysModTime: "2024-03-06T14:33:03.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002UL",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:50.000Z",
    sysModTime: "2024-03-06T15:50:50.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F006R00000002UP",
    roleId: "R00000002",
    featureId: "F006",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:06.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:06.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:06.000Z",
    sysModTime: "2024-03-06T14:51:06.000Z",
    featureIdentifier: "clienthighlights",
    description: "Client Highlights",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000009",
    status: 2,
    statusDate: "2024-03-06T12:19:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:19:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:19:58.000Z",
    sysModTime: "2024-03-06T12:19:58.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002CR",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:12.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:12.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:12.000Z",
    sysModTime: "2024-03-06T14:17:12.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002DL",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:46.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:46.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:46.000Z",
    sysModTime: "2024-03-06T15:09:46.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002DO",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002DV",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:18.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:18.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:18.000Z",
    sysModTime: "2024-03-06T13:02:18.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002LV",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000008",
    status: 2,
    statusDate: "2024-03-06T12:45:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:41.000Z",
    sysModTime: "2024-03-06T12:45:41.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002RD",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:06.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:06.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:06.000Z",
    sysModTime: "2024-03-06T14:33:06.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002UL",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:53.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:53.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:53.000Z",
    sysModTime: "2024-03-06T15:50:53.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F007R00000002UP",
    roleId: "R00000002",
    featureId: "F007",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:09.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:09.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:09.000Z",
    sysModTime: "2024-03-06T14:51:09.000Z",
    featureIdentifier: "clientdetails",
    description: "Client Details",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:01.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:01.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:01.000Z",
    sysModTime: "2024-03-06T12:20:01.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002CR",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:15.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:15.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:15.000Z",
    sysModTime: "2024-03-06T14:17:15.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002DL",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:49.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:49.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:49.000Z",
    sysModTime: "2024-03-06T15:09:49.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002DO",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002DV",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:21.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:21.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:21.000Z",
    sysModTime: "2024-03-06T13:02:21.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002LV",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:44.000Z",
    sysModTime: "2024-03-06T12:45:44.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002RD",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:09.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:09.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:09.000Z",
    sysModTime: "2024-03-06T14:33:09.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002UL",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:56.000Z",
    sysModTime: "2024-03-06T15:50:56.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F008R00000002UP",
    roleId: "R00000002",
    featureId: "F008",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:12.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:12.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:12.000Z",
    sysModTime: "2024-03-06T14:51:12.000Z",
    featureIdentifier: "clientprojects",
    description: "Client Projects",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:04.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:04.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:04.000Z",
    sysModTime: "2024-03-06T12:20:04.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002CR",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:18.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:18.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:18.000Z",
    sysModTime: "2024-03-06T14:17:18.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002DL",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:52.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:52.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:52.000Z",
    sysModTime: "2024-03-06T15:09:52.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002DO",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002DV",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:24.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:24.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:24.000Z",
    sysModTime: "2024-03-06T13:02:24.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002LV",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:47.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:47.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:47.000Z",
    sysModTime: "2024-03-06T12:45:47.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002RD",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:12.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:12.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:12.000Z",
    sysModTime: "2024-03-06T14:33:12.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002UL",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:50:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:50:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:50:59.000Z",
    sysModTime: "2024-03-06T15:50:59.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F009R00000002UP",
    roleId: "R00000002",
    featureId: "F009",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:16.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:16.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:16.000Z",
    sysModTime: "2024-03-06T14:51:16.000Z",
    featureIdentifier: "clientworkflows",
    description: "Client Workflows",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:07.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:07.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:07.000Z",
    sysModTime: "2024-03-06T12:20:07.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002CR",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:21.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:21.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:21.000Z",
    sysModTime: "2024-03-06T14:17:21.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002DL",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:55.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:55.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:55.000Z",
    sysModTime: "2024-03-06T15:09:55.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002DO",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002DV",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:27.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:27.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:27.000Z",
    sysModTime: "2024-03-06T13:02:27.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002LV",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:50.000Z",
    sysModTime: "2024-03-06T12:45:50.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002RD",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:15.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:15.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:15.000Z",
    sysModTime: "2024-03-06T14:33:15.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002UL",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:03.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:03.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:03.000Z",
    sysModTime: "2024-03-06T15:51:03.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F010R00000002UP",
    roleId: "R00000002",
    featureId: "F010",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:19.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:19.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:19.000Z",
    sysModTime: "2024-03-06T14:51:19.000Z",
    featureIdentifier: "clientdocuments",
    description: "Client Documents",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:10.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:10.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:10.000Z",
    sysModTime: "2024-03-06T12:20:10.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002CR",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:24.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:24.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:24.000Z",
    sysModTime: "2024-03-06T14:17:24.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002DL",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:09:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:09:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:09:58.000Z",
    sysModTime: "2024-03-06T15:09:58.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002DO",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002DV",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:30.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:30.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:30.000Z",
    sysModTime: "2024-03-06T13:02:30.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002LV",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:53.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:53.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:53.000Z",
    sysModTime: "2024-03-06T12:45:53.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002RD",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:18.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:18.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:18.000Z",
    sysModTime: "2024-03-06T14:33:18.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002UL",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:06.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:06.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:06.000Z",
    sysModTime: "2024-03-06T15:51:06.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F011R00000002UP",
    roleId: "R00000002",
    featureId: "F011",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:22.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:22.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:22.000Z",
    sysModTime: "2024-03-06T14:51:22.000Z",
    featureIdentifier: "clientcontacts",
    description: "Client Contacts",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:13.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:13.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:13.000Z",
    sysModTime: "2024-03-06T12:20:13.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002CR",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:27.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:27.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:27.000Z",
    sysModTime: "2024-03-06T14:17:27.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002DL",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:01.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:01.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:01.000Z",
    sysModTime: "2024-03-06T15:10:01.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002DO",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002DV",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:33.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:33.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:33.000Z",
    sysModTime: "2024-03-06T13:02:33.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002LV",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:56.000Z",
    sysModTime: "2024-03-06T12:45:56.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002RD",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:21.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:21.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:21.000Z",
    sysModTime: "2024-03-06T14:33:21.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002UL",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:09.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:09.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:09.000Z",
    sysModTime: "2024-03-06T15:51:09.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F012R00000002UP",
    roleId: "R00000002",
    featureId: "F012",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:25.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:25.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:25.000Z",
    sysModTime: "2024-03-06T14:51:25.000Z",
    featureIdentifier: "clientcontactfinance",
    description: "Client Contact Finance",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:16.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:16.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:16.000Z",
    sysModTime: "2024-03-06T12:20:16.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002CR",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:30.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:30.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:30.000Z",
    sysModTime: "2024-03-06T14:17:30.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002DL",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:04.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:04.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:04.000Z",
    sysModTime: "2024-03-06T15:10:04.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002DO",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002DV",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:36.000Z",
    sysModTime: "2024-03-06T13:02:36.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002LV",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:45:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:45:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:45:59.000Z",
    sysModTime: "2024-03-06T12:45:59.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002RD",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:24.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:24.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:24.000Z",
    sysModTime: "2024-03-06T14:33:24.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002UL",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:12.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:12.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:12.000Z",
    sysModTime: "2024-03-06T15:51:12.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F013R00000002UP",
    roleId: "R00000002",
    featureId: "F013",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:28.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:28.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:28.000Z",
    sysModTime: "2024-03-06T14:51:28.000Z",
    featureIdentifier: "project",
    description: "Project",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:19.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:19.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:19.000Z",
    sysModTime: "2024-03-06T12:20:19.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002CR",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:33.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:33.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:33.000Z",
    sysModTime: "2024-03-06T14:17:33.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002DL",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:07.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:07.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:07.000Z",
    sysModTime: "2024-03-06T15:10:07.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002DO",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002DV",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:39.000Z",
    sysModTime: "2024-03-06T13:02:39.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002LV",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:02.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:02.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:02.000Z",
    sysModTime: "2024-03-06T12:46:02.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002RD",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:27.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:27.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:27.000Z",
    sysModTime: "2024-03-06T14:33:27.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002UL",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:15.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:15.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:15.000Z",
    sysModTime: "2024-03-06T15:51:15.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F014R00000002UP",
    roleId: "R00000002",
    featureId: "F014",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:31.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:31.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:31.000Z",
    sysModTime: "2024-03-06T14:51:31.000Z",
    featureIdentifier: "projectfinancials",
    description: "Project Financials",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:22.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:22.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:22.000Z",
    sysModTime: "2024-03-06T12:20:22.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002CR",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:36.000Z",
    sysModTime: "2024-03-06T14:17:36.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002DL",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:10.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:10.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:10.000Z",
    sysModTime: "2024-03-06T15:10:10.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002DO",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002DV",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:42.000Z",
    sysModTime: "2024-03-06T13:02:42.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002LV",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:05.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:05.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:05.000Z",
    sysModTime: "2024-03-06T12:46:05.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002RD",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:30.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:30.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:30.000Z",
    sysModTime: "2024-03-06T14:33:30.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002UL",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:18.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:18.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:18.000Z",
    sysModTime: "2024-03-06T15:51:18.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F015R00000002UP",
    roleId: "R00000002",
    featureId: "F015",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:34.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:34.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:34.000Z",
    sysModTime: "2024-03-06T14:51:34.000Z",
    featureIdentifier: "projectdetails",
    description: "Project Details",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:25.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:25.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:25.000Z",
    sysModTime: "2024-03-06T12:20:25.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002CR",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:39.000Z",
    sysModTime: "2024-03-06T14:17:39.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002DL",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:13.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:13.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:13.000Z",
    sysModTime: "2024-03-06T15:10:13.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002DO",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002DV",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:45.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:45.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:45.000Z",
    sysModTime: "2024-03-06T13:02:45.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002LV",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:08.000Z",
    sysModTime: "2024-03-06T12:46:08.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002RD",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:33.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:33.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:33.000Z",
    sysModTime: "2024-03-06T14:33:33.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002UL",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:22.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:22.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:22.000Z",
    sysModTime: "2024-03-06T15:51:22.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F016R00000002UP",
    roleId: "R00000002",
    featureId: "F016",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:37.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:37.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:37.000Z",
    sysModTime: "2024-03-06T14:51:37.000Z",
    featureIdentifier: "projecthistory",
    description: "Project history",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:28.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:28.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:28.000Z",
    sysModTime: "2024-03-06T12:20:28.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002CR",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:43.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:43.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:43.000Z",
    sysModTime: "2024-03-06T14:17:43.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002DL",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:16.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:16.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:16.000Z",
    sysModTime: "2024-03-06T15:10:16.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002DO",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002DV",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:48.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:48.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:48.000Z",
    sysModTime: "2024-03-06T13:02:48.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002LV",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:11.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:11.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:11.000Z",
    sysModTime: "2024-03-06T12:46:11.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002RD",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:36.000Z",
    sysModTime: "2024-03-06T14:33:36.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002UL",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:25.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:25.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:25.000Z",
    sysModTime: "2024-03-06T15:51:25.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F017R00000002UP",
    roleId: "R00000002",
    featureId: "F017",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:40.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:40.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:40.000Z",
    sysModTime: "2024-03-06T14:51:40.000Z",
    featureIdentifier: "projectteammembers",
    description: "Project Team Members",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:31.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:31.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:31.000Z",
    sysModTime: "2024-03-06T12:20:31.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002CR",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:46.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:46.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:46.000Z",
    sysModTime: "2024-03-06T14:17:46.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002DL",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:19.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:19.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:19.000Z",
    sysModTime: "2024-03-06T15:10:19.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002DO",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002DV",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:51.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:51.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:51.000Z",
    sysModTime: "2024-03-06T13:02:51.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002LV",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:14.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:14.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:14.000Z",
    sysModTime: "2024-03-06T12:46:14.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002RD",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:39.000Z",
    sysModTime: "2024-03-06T14:33:39.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002UL",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:28.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:28.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:28.000Z",
    sysModTime: "2024-03-06T15:51:28.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F018R00000002UP",
    roleId: "R00000002",
    featureId: "F018",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:44.000Z",
    sysModTime: "2024-03-06T14:51:44.000Z",
    featureIdentifier: "projecttimesheet",
    description: "Project Timesheet",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:35.000Z",
    sysModTime: "2024-03-06T12:20:35.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002CR",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:49.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:49.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:49.000Z",
    sysModTime: "2024-03-06T14:17:49.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002DL",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:23.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:23.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:23.000Z",
    sysModTime: "2024-03-06T15:10:23.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002DO",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002DV",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:54.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:54.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:54.000Z",
    sysModTime: "2024-03-06T13:02:54.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002LV",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:17.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:17.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:17.000Z",
    sysModTime: "2024-03-06T12:46:17.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002RD",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:42.000Z",
    sysModTime: "2024-03-06T14:33:42.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002UL",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:32.000Z",
    sysModTime: "2024-03-06T15:51:32.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F019R00000002UP",
    roleId: "R00000002",
    featureId: "F019",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:47.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:47.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:47.000Z",
    sysModTime: "2024-03-06T14:51:47.000Z",
    featureIdentifier: "projectrndoverride",
    description: "Project R&D override",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:38.000Z",
    sysModTime: "2024-03-06T12:20:38.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002CR",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:52.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:52.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:52.000Z",
    sysModTime: "2024-03-06T14:17:52.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002DL",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:26.000Z",
    sysModTime: "2024-03-06T15:10:26.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002DO",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002DV",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:02:57.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:02:57.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:02:57.000Z",
    sysModTime: "2024-03-06T13:02:57.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002LV",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:20.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:20.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:20.000Z",
    sysModTime: "2024-03-06T12:46:20.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002RD",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:45.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:45.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:45.000Z",
    sysModTime: "2024-03-06T14:33:45.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002UL",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:35.000Z",
    sysModTime: "2024-03-06T15:51:35.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F020R00000002UP",
    roleId: "R00000002",
    featureId: "F020",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:50.000Z",
    sysModTime: "2024-03-06T14:51:50.000Z",
    featureIdentifier: "projectjournal",
    description: "Project Journal",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:41.000Z",
    sysModTime: "2024-03-06T12:20:41.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002CR",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:55.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:55.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:55.000Z",
    sysModTime: "2024-03-06T14:17:55.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002DL",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:29.000Z",
    sysModTime: "2024-03-06T15:10:29.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002DO",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002DV",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:00.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:00.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:00.000Z",
    sysModTime: "2024-03-06T13:03:00.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002LV",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:23.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:23.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:23.000Z",
    sysModTime: "2024-03-06T12:46:23.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002RD",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:48.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:48.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:48.000Z",
    sysModTime: "2024-03-06T14:33:48.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002UL",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:38.000Z",
    sysModTime: "2024-03-06T15:51:38.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F021R00000002UP",
    roleId: "R00000002",
    featureId: "F021",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:53.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:53.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:53.000Z",
    sysModTime: "2024-03-06T14:51:53.000Z",
    featureIdentifier: "projectdocuments",
    description: "Project Documents",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:44.000Z",
    sysModTime: "2024-03-06T12:20:44.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002CR",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:17:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:17:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:17:58.000Z",
    sysModTime: "2024-03-06T14:17:58.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002DL",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:32.000Z",
    sysModTime: "2024-03-06T15:10:32.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002DO",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002DV",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:03.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:03.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:03.000Z",
    sysModTime: "2024-03-06T13:03:03.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002LV",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:26.000Z",
    sysModTime: "2024-03-06T12:46:26.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002RD",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:51.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:51.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:51.000Z",
    sysModTime: "2024-03-06T14:33:51.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002UL",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:41.000Z",
    sysModTime: "2024-03-06T15:51:41.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F022R00000002UP",
    roleId: "R00000002",
    featureId: "F022",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:56.000Z",
    sysModTime: "2024-03-06T14:51:56.000Z",
    featureIdentifier: "projectrndhistory",
    description: "Project R&D History",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:47.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:47.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:47.000Z",
    sysModTime: "2024-03-06T12:20:47.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002CR",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:01.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:01.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:01.000Z",
    sysModTime: "2024-03-06T14:18:01.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002DL",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:37.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:37.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:37.000Z",
    sysModTime: "2024-03-06T15:10:37.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002DO",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002DV",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:06.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:06.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:06.000Z",
    sysModTime: "2024-03-06T13:03:06.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002LV",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:29.000Z",
    sysModTime: "2024-03-06T12:46:29.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002RD",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:54.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:54.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:54.000Z",
    sysModTime: "2024-03-06T14:33:54.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002UL",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:44.000Z",
    sysModTime: "2024-03-06T15:51:44.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F023R00000002UP",
    roleId: "R00000002",
    featureId: "F023",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:51:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:51:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:51:59.000Z",
    sysModTime: "2024-03-06T14:51:59.000Z",
    featureIdentifier: "interactions",
    description: "Interactions",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:50.000Z",
    sysModTime: "2024-03-06T12:20:50.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002CR",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:04.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:04.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:04.000Z",
    sysModTime: "2024-03-06T14:18:04.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002DL",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:42.000Z",
    sysModTime: "2024-03-06T15:10:42.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002DO",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002DV",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:10.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:10.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:10.000Z",
    sysModTime: "2024-03-06T13:03:10.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002LV",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:32.000Z",
    sysModTime: "2024-03-06T12:46:32.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002RD",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:33:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:33:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:33:58.000Z",
    sysModTime: "2024-03-06T14:33:58.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002UL",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:48.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:48.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:48.000Z",
    sysModTime: "2024-03-06T15:51:48.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F024R00000002UP",
    roleId: "R00000002",
    featureId: "F024",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:02.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:02.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:02.000Z",
    sysModTime: "2024-03-06T14:52:02.000Z",
    featureIdentifier: "tasks",
    description: "Tasks",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:53.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:53.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:53.000Z",
    sysModTime: "2024-03-06T12:20:53.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002CR",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:07.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:07.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:07.000Z",
    sysModTime: "2024-03-06T14:18:07.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002DL",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:46.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:46.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:46.000Z",
    sysModTime: "2024-03-06T15:10:46.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002DO",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002DV",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:13.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:13.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:13.000Z",
    sysModTime: "2024-03-06T13:03:13.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002LV",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:35.000Z",
    sysModTime: "2024-03-06T12:46:35.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002RD",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:01.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:01.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:01.000Z",
    sysModTime: "2024-03-06T14:34:01.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002UL",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:51.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:51.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:51.000Z",
    sysModTime: "2024-03-06T15:51:51.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F025R00000002UP",
    roleId: "R00000002",
    featureId: "F025",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:05.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:05.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:05.000Z",
    sysModTime: "2024-03-06T14:52:05.000Z",
    featureIdentifier: "claims",
    description: "Claims",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:56.000Z",
    sysModTime: "2024-03-06T12:20:56.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002CR",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:10.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:10.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:10.000Z",
    sysModTime: "2024-03-06T14:18:10.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002DL",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:49.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:49.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:49.000Z",
    sysModTime: "2024-03-06T15:10:49.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002DO",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002DV",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:16.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:16.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:16.000Z",
    sysModTime: "2024-03-06T13:03:16.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002LV",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:38.000Z",
    sysModTime: "2024-03-06T12:46:38.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002RD",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:04.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:04.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:04.000Z",
    sysModTime: "2024-03-06T14:34:04.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002UL",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:54.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:54.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:54.000Z",
    sysModTime: "2024-03-06T15:51:54.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F026R00000002UP",
    roleId: "R00000002",
    featureId: "F026",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:08.000Z",
    sysModTime: "2024-03-06T14:52:08.000Z",
    featureIdentifier: "claimsrelatedprojects",
    description: "Claims Related Projects",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:20:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:20:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:20:59.000Z",
    sysModTime: "2024-03-06T12:20:59.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002CR",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:13.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:13.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:13.000Z",
    sysModTime: "2024-03-06T14:18:13.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002DL",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:52.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:52.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:52.000Z",
    sysModTime: "2024-03-06T15:10:52.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002DO",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002DV",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:19.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:19.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:19.000Z",
    sysModTime: "2024-03-06T13:03:19.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002LV",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:41.000Z",
    sysModTime: "2024-03-06T12:46:41.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002RD",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:07.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:07.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:07.000Z",
    sysModTime: "2024-03-06T14:34:07.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002UL",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:51:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:51:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:51:58.000Z",
    sysModTime: "2024-03-06T15:51:58.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F027R00000002UP",
    roleId: "R00000002",
    featureId: "F027",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:11.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:11.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:11.000Z",
    sysModTime: "2024-03-06T14:52:11.000Z",
    featureIdentifier: "claimssummary",
    description: "Claims Summary",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:02.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:02.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:02.000Z",
    sysModTime: "2024-03-06T12:21:02.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002CR",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:16.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:16.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:16.000Z",
    sysModTime: "2024-03-06T14:18:16.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002DL",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:10:55.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:10:55.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:10:55.000Z",
    sysModTime: "2024-03-06T15:10:55.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002DO",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002DV",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:22.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:22.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:22.000Z",
    sysModTime: "2024-03-06T13:03:22.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002LV",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:44.000Z",
    sysModTime: "2024-03-06T12:46:44.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002RD",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:11.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:11.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:11.000Z",
    sysModTime: "2024-03-06T14:34:11.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002UL",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:01.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:01.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:01.000Z",
    sysModTime: "2024-03-06T15:52:01.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F028R00000002UP",
    roleId: "R00000002",
    featureId: "F028",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:14.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:14.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:14.000Z",
    sysModTime: "2024-03-06T14:52:14.000Z",
    featureIdentifier: "standardreports",
    description: "Standard Reports",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:08.000Z",
    sysModTime: "2024-03-06T12:21:08.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002CR",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:22.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:22.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:22.000Z",
    sysModTime: "2024-03-06T14:18:22.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002DL",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:01.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:01.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:01.000Z",
    sysModTime: "2024-03-06T15:11:01.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002DO",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002DV",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:29.000Z",
    sysModTime: "2024-03-06T13:03:29.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002LV",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:50.000Z",
    sysModTime: "2024-03-06T12:46:50.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002RD",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:17.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:17.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:17.000Z",
    sysModTime: "2024-03-06T14:34:17.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002UL",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:07.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:07.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:07.000Z",
    sysModTime: "2024-03-06T15:52:07.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F029R00000002UP",
    roleId: "R00000002",
    featureId: "F029",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:20.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:20.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:20.000Z",
    sysModTime: "2024-03-06T14:52:20.000Z",
    featureIdentifier: "documents",
    description: "Documents",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:11.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:11.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:11.000Z",
    sysModTime: "2024-03-06T12:21:11.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002CR",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:26.000Z",
    sysModTime: "2024-03-06T14:18:26.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002DL",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:04.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:04.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:04.000Z",
    sysModTime: "2024-03-06T15:11:04.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002DO",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002DV",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:32.000Z",
    sysModTime: "2024-03-06T13:03:32.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002LV",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:53.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:53.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:53.000Z",
    sysModTime: "2024-03-06T12:46:53.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002RD",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:20.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:20.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:20.000Z",
    sysModTime: "2024-03-06T14:34:20.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002UL",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:10.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:10.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:10.000Z",
    sysModTime: "2024-03-06T15:52:10.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F030R00000002UP",
    roleId: "R00000002",
    featureId: "F030",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:23.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:23.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:23.000Z",
    sysModTime: "2024-03-06T14:52:23.000Z",
    featureIdentifier: "workbench",
    description: "Workbench",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:14.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:14.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:14.000Z",
    sysModTime: "2024-03-06T12:21:14.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002CR",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:29.000Z",
    sysModTime: "2024-03-06T14:18:29.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002DL",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:07.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:07.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:07.000Z",
    sysModTime: "2024-03-06T15:11:07.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002DO",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002DV",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:35.000Z",
    sysModTime: "2024-03-06T13:03:35.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002LV",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:56.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:56.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:56.000Z",
    sysModTime: "2024-03-06T12:46:56.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002RD",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:23.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:23.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:23.000Z",
    sysModTime: "2024-03-06T14:34:23.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002UL",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:13.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:13.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:13.000Z",
    sysModTime: "2024-03-06T15:52:13.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F031R00000002UP",
    roleId: "R00000002",
    featureId: "F031",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:26.000Z",
    sysModTime: "2024-03-06T14:52:26.000Z",
    featureIdentifier: "standardworkflows",
    description: "Standard Workflows",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:17.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:17.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:17.000Z",
    sysModTime: "2024-03-06T12:21:17.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002CR",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:32.000Z",
    sysModTime: "2024-03-06T14:18:32.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002DL",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:11.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:11.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:11.000Z",
    sysModTime: "2024-03-06T15:11:11.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002DO",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002DV",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:38.000Z",
    sysModTime: "2024-03-06T13:03:38.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002LV",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:46:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:46:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:46:59.000Z",
    sysModTime: "2024-03-06T12:46:59.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002RD",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:27.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:27.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:27.000Z",
    sysModTime: "2024-03-06T14:34:27.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002UL",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:16.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:16.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:16.000Z",
    sysModTime: "2024-03-06T15:52:16.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F032R00000002UP",
    roleId: "R00000002",
    featureId: "F032",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:29.000Z",
    sysModTime: "2024-03-06T14:52:29.000Z",
    featureIdentifier: "portfolio",
    description: "Portfolio",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:20.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:20.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:20.000Z",
    sysModTime: "2024-03-06T12:21:20.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002CR",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:35.000Z",
    sysModTime: "2024-03-06T14:18:35.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002DL",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:14.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:14.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:14.000Z",
    sysModTime: "2024-03-06T15:11:14.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002DO",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002DV",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:41.000Z",
    sysModTime: "2024-03-06T13:03:41.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002LV",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:47:03.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:47:03.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:47:03.000Z",
    sysModTime: "2024-03-06T12:47:03.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002RD",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:30.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:30.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:30.000Z",
    sysModTime: "2024-03-06T14:34:30.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002UL",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:19.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:19.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:19.000Z",
    sysModTime: "2024-03-06T15:52:19.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F033R00000002UP",
    roleId: "R00000002",
    featureId: "F033",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:33.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:33.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:33.000Z",
    sysModTime: "2024-03-06T14:52:33.000Z",
    featureIdentifier: "contacts",
    description: "Contacts",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:23.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:23.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:23.000Z",
    sysModTime: "2024-03-06T12:21:23.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002CR",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:38.000Z",
    sysModTime: "2024-03-06T14:18:38.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002DL",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:17.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:17.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:17.000Z",
    sysModTime: "2024-03-06T15:11:17.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002DO",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002DV",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:45.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:45.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:45.000Z",
    sysModTime: "2024-03-06T13:03:45.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002LV",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:47:06.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:47:06.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:47:06.000Z",
    sysModTime: "2024-03-06T12:47:06.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002RD",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:33.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:33.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:33.000Z",
    sysModTime: "2024-03-06T14:34:33.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002UL",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:22.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:22.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:22.000Z",
    sysModTime: "2024-03-06T15:52:22.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F034R00000002UP",
    roleId: "R00000002",
    featureId: "F034",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:36.000Z",
    sysModTime: "2024-03-06T14:52:36.000Z",
    featureIdentifier: "contactsdetails",
    description: "Contacts Details",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:26.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:26.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:26.000Z",
    sysModTime: "2024-03-06T12:21:26.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002CR",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:41.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:41.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:41.000Z",
    sysModTime: "2024-03-06T14:18:41.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002DL",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:20.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:20.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:20.000Z",
    sysModTime: "2024-03-06T15:11:20.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002DO",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002DV",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:48.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:48.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:48.000Z",
    sysModTime: "2024-03-06T13:03:48.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002LV",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:47:09.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:47:09.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:47:09.000Z",
    sysModTime: "2024-03-06T12:47:09.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002RD",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:36.000Z",
    sysModTime: "2024-03-06T14:34:36.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002UL",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:25.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:25.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:25.000Z",
    sysModTime: "2024-03-06T15:52:25.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F035R00000002UP",
    roleId: "R00000002",
    featureId: "F035",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:39.000Z",
    sysModTime: "2024-03-06T14:52:39.000Z",
    featureIdentifier: "contactsprojects",
    description: "Contacts Projects",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:29.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:29.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:29.000Z",
    sysModTime: "2024-03-06T12:21:29.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002CR",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:44.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:44.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:44.000Z",
    sysModTime: "2024-03-06T14:18:44.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002DL",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:23.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:23.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:23.000Z",
    sysModTime: "2024-03-06T15:11:23.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002DO",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002DV",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:51.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:51.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:51.000Z",
    sysModTime: "2024-03-06T13:03:51.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002LV",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:47:12.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:47:12.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:47:12.000Z",
    sysModTime: "2024-03-06T12:47:12.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002RD",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:39.000Z",
    sysModTime: "2024-03-06T14:34:39.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002UL",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:28.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:28.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:28.000Z",
    sysModTime: "2024-03-06T15:52:28.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F036R00000002UP",
    roleId: "R00000002",
    featureId: "F036",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:42.000Z",
    sysModTime: "2024-03-06T14:52:42.000Z",
    featureIdentifier: "contactsrdexpense",
    description: "Contacts R&D expense",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:32.000Z",
    sysModTime: "2024-03-06T12:21:32.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002CR",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:47.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:47.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:47.000Z",
    sysModTime: "2024-03-06T14:18:47.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002DL",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:27.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:27.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:27.000Z",
    sysModTime: "2024-03-06T15:11:27.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002DO",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002DV",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:54.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:54.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:54.000Z",
    sysModTime: "2024-03-06T13:03:54.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002LV",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:47:15.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:47:15.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:47:15.000Z",
    sysModTime: "2024-03-06T12:47:15.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002RD",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:42.000Z",
    sysModTime: "2024-03-06T14:34:42.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002UL",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:32.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:32.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:32.000Z",
    sysModTime: "2024-03-06T15:52:32.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F037R00000002UP",
    roleId: "R00000002",
    featureId: "F037",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:45.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:45.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:45.000Z",
    sysModTime: "2024-03-06T14:52:45.000Z",
    featureIdentifier: "contactsalary",
    description: "Contact Salary",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000009",
    status: 1,
    statusDate: "2024-03-06T12:21:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:21:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:21:35.000Z",
    sysModTime: "2024-03-06T12:21:35.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002CR",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000007",
    status: 1,
    statusDate: "2024-03-06T14:18:50.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:18:50.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:18:50.000Z",
    sysModTime: "2024-03-06T14:18:50.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002DL",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000005",
    status: 1,
    statusDate: "2024-03-06T15:11:30.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:11:30.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:11:30.000Z",
    sysModTime: "2024-03-06T15:11:30.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002DO",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000006",
    status: 1,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002DV",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000004",
    status: 1,
    statusDate: "2024-03-06T13:03:57.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:03:57.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:03:57.000Z",
    sysModTime: "2024-03-06T13:03:57.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002LV",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:47:18.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:47:18.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:47:18.000Z",
    sysModTime: "2024-03-06T12:47:18.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002RD",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:34:45.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:34:45.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:34:45.000Z",
    sysModTime: "2024-03-06T14:34:45.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002UL",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000002",
    status: 1,
    statusDate: "2024-03-06T15:52:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:52:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:52:35.000Z",
    sysModTime: "2024-03-06T15:52:35.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F038R00000002UP",
    roleId: "R00000002",
    featureId: "F038",
    permissionId: "P000000001",
    status: 1,
    statusDate: "2024-03-06T14:52:48.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:52:48.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:52:48.000Z",
    sysModTime: "2024-03-06T14:52:48.000Z",
    featureIdentifier: "trainingdocumentation",
    description: "Training & Documentation",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000009",
    status: 2,
    statusDate: "2024-03-06T12:34:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:34:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:34:08.000Z",
    sysModTime: "2024-03-06T12:34:08.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002CR",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000007",
    status: 0,
    statusDate: "2024-03-06T14:31:36.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:31:36.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:31:36.000Z",
    sysModTime: "2024-03-06T14:31:36.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002DL",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000005",
    status: 0,
    statusDate: "2024-03-06T15:44:59.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:44:59.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:44:59.000Z",
    sysModTime: "2024-03-06T15:44:59.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002DO",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000006",
    status: 0,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002DV",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000004",
    status: 0,
    statusDate: "2024-03-06T13:16:49.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:16:49.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:16:49.000Z",
    sysModTime: "2024-03-06T13:16:49.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002LV",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:59:51.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:59:51.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:59:51.000Z",
    sysModTime: "2024-03-06T12:59:51.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002RD",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:47:40.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:47:40.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:47:40.000Z",
    sysModTime: "2024-03-06T14:47:40.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002UL",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000002",
    status: 0,
    statusDate: "2024-03-06T16:05:35.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:05:35.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:05:35.000Z",
    sysModTime: "2024-03-06T16:05:35.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F039R00000002UP",
    roleId: "R00000002",
    featureId: "F039",
    permissionId: "P000000001",
    status: 0,
    statusDate: "2024-03-06T15:05:21.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:05:21.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:05:21.000Z",
    sysModTime: "2024-03-06T15:05:21.000Z",
    featureIdentifier: "homepagekpis",
    description: "Home Page - KPIs",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000009",
    status: 2,
    statusDate: "2024-03-06T12:34:12.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:34:12.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:34:12.000Z",
    sysModTime: "2024-03-06T12:34:12.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002CR",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000007",
    status: 0,
    statusDate: "2024-03-06T14:31:39.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:31:39.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:31:39.000Z",
    sysModTime: "2024-03-06T14:31:39.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002DL",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000005",
    status: 0,
    statusDate: "2024-03-06T15:45:02.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:45:02.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:45:02.000Z",
    sysModTime: "2024-03-06T15:45:02.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002DO",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000006",
    status: 0,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002DV",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000004",
    status: 0,
    statusDate: "2024-03-06T13:16:52.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:16:52.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:16:52.000Z",
    sysModTime: "2024-03-06T13:16:52.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002LV",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:59:54.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:59:54.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:59:54.000Z",
    sysModTime: "2024-03-06T12:59:54.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002RD",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:47:43.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:47:43.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:47:43.000Z",
    sysModTime: "2024-03-06T14:47:43.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002UL",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000002",
    status: 0,
    statusDate: "2024-03-06T16:05:38.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:05:38.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:05:38.000Z",
    sysModTime: "2024-03-06T16:05:38.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F040R00000002UP",
    roleId: "R00000002",
    featureId: "F040",
    permissionId: "P000000001",
    status: 0,
    statusDate: "2024-03-06T15:05:24.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:05:24.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:05:24.000Z",
    sysModTime: "2024-03-06T15:05:24.000Z",
    featureIdentifier: "homepageactivitysection",
    description: "Home Page - Activity section",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000009",
    status: 2,
    statusDate: "2024-03-06T12:34:15.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:34:15.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:34:15.000Z",
    sysModTime: "2024-03-06T12:34:15.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Search",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002CR",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000007",
    status: 0,
    statusDate: "2024-03-06T14:31:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:31:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:31:42.000Z",
    sysModTime: "2024-03-06T14:31:42.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Create",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002DL",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000005",
    status: 0,
    statusDate: "2024-03-06T15:45:05.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:45:05.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:45:05.000Z",
    sysModTime: "2024-03-06T15:45:05.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Delete",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002DO",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000006",
    status: 0,
    statusDate: "2024-03-06T16:07:08.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:07:08.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:07:08.000Z",
    sysModTime: "2024-03-06T16:07:08.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Download",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002DV",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000004",
    status: 0,
    statusDate: "2024-03-06T13:16:55.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T13:16:55.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T13:16:55.000Z",
    sysModTime: "2024-03-06T13:16:55.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Details View",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002LV",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000008",
    status: 1,
    statusDate: "2024-03-06T12:59:58.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T12:59:58.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T12:59:58.000Z",
    sysModTime: "2024-03-06T12:59:58.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "List view",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002RD",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000003",
    status: 1,
    statusDate: "2024-03-06T14:47:46.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T14:47:46.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T14:47:46.000Z",
    sysModTime: "2024-03-06T14:47:46.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Read",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002UL",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000002",
    status: 0,
    statusDate: "2024-03-06T16:05:42.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T16:05:42.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T16:05:42.000Z",
    sysModTime: "2024-03-06T16:05:42.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Upload",
    role: "Tax Consultant Admin",
  },
  {
    roleFeaturesId: "F041R00000002UP",
    roleId: "R00000002",
    featureId: "F041",
    permissionId: "P000000001",
    status: 0,
    statusDate: "2024-03-06T15:05:27.000Z",
    createdBy: "PU001",
    createdTime: "2024-03-06T15:05:27.000Z",
    modifiedBy: "PU001",
    modifiedTime: "2024-03-06T15:05:27.000Z",
    sysModTime: "2024-03-06T15:05:27.000Z",
    featureIdentifier: "homepagealertssection",
    description: "Home Page - Alerts section",
    permissionName: "Update",
    role: "Tax Consultant Admin",
  },
];
