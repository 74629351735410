import { Box, TextField } from '@mui/material';
import React from 'react';

const styles = {
    textField: {
        fontSize: '0.82rem',
        padding: '2px 0px',
        height: '32px',
        width: '100px',
        borderRadius: '20px',
    },
};

const MinMaxFilter = ({
    minName,
    maxName,
    type,
    minValue,
    maxValue,
    handleFilterChange,
    minPlaceholder,
    maxPlaceholder,
    positiveNumberError,
    field,
}) => {
    return (
        <Box display="flex" gap={3}>
            {/* Min Value Input */}
            <TextField
                name={minName}
                type={type}
                value={minValue || ''} // Ensure value is controlled (fallback to empty string)
                onChange={(e) => handleFilterChange({ field, scale: 'min', value: e.target.value })} // Pass the actual value
                placeholder={minPlaceholder}
                fullWidth
                InputProps={{
                    sx: styles.textField,
                }}
                InputLabelProps={{
                    style: { width: '100%', marginTop: '-10px' },
                }}
                error={!!positiveNumberError}
                helperText={positiveNumberError || ''}
                FormHelperTextProps={{
                    sx: { textAlign: 'left', padding: 0, margin: 0, fontSize: '0.8rem', color: 'red' },
                }}
                sx={{ padding: '0px' }}
            />

            {/* Max Value Input */}
            <TextField
                name={maxName}
                type={type}
                value={maxValue || ''} // Ensure value is controlled (fallback to empty string)
                onChange={(e) => handleFilterChange({ field, scale: 'max', value: e.target.value })} // Pass the actual value
                placeholder={maxPlaceholder}
                fullWidth
                sx={{ marginRight: '10px' }}
                InputProps={{
                    sx: styles.textField,
                }}
            />
        </Box>
    );
};

export default MinMaxFilter;
