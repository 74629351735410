import { Box, TextField } from '@mui/material'
import React from 'react'

const styles={
    textField: {
        fontSize: '0.82rem',
        padding: '2px 0px',
        height: '32px',
        width: "120px",
        borderRadius: "20px",
    },
}

const DateFilterComponent = ({sentStartDate, sentEndDate, handleDateChange, startLabel, endLabel, dateError}) => {
    return (
        <Box display="flex" gap={3}>
            <TextField
                type="date"
                label={"start date"}
                value={sentStartDate || ""}
                onChange={handleDateChange(startLabel, "min")}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    sx: styles.textField,
                }}
                error={!!dateError}
                helperText={dateError}
            />
            <TextField
                type="date"
                label="end date"
                value={sentEndDate || ""}
                onChange={handleDateChange(endLabel, "max")}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    sx: styles.textField,
                }}
            />
        </Box>
    )
}

export default DateFilterComponent